import React, { useState, useEffect } from 'react'
import { Select, Skeleton, TextInput } from '@mantine/core'
import styles from './Grid.module.scss'
import { Link } from 'gatsby'
import _ from 'lodash'

const Grid = ({ pages }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredFormats, setFilteredFormats] = useState(pages)
  const [filterLetters, setFilterLetters] = useState([])

  useEffect(() => {
    const filtered = pages.filter(format => {
      const title = format.frontmatter.title.toLowerCase()
      return title.includes(searchTerm.toLowerCase())
    })
    setFilteredFormats(filtered)
  }, [pages, searchTerm])

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  const handleFilter = letter => {
    if (filterLetters.includes(letter)) {
      setFilteredFormats(pages)
      setFilterLetters([])
      return
    } else {
      const filtered = pages.filter(page => {
        const firstLetter = page.frontmatter.title.charAt(0).toLowerCase()
        return firstLetter === letter.toLowerCase()
      })
      setFilteredFormats(filtered)
      setFilterLetters([letter])
    }
  }

  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.filter}>
            <TextInput
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              radius="lg"
              size="xl"
              classNames={{ input: styles.searchInput }}
            />
            <div className={styles.alphabetButtons}>
              {Array.from(Array(26)).map((_, index) => {
                const letter = String.fromCharCode(97 + index)
                return (
                  <button
                    key={letter}
                    onClick={() => handleFilter(letter)}
                    className={`${styles.letterButton} ${filterLetters.includes(
                      letter
                    ) && styles.active}`}
                  >
                    {letter.toUpperCase()}
                  </button>
                )
              })}
            </div>
          </div>
          <div>
            <Skeleton visible={!pages}>
              <div className={styles.grid}>
                {filteredFormats.map(m => (
                  <GridItem
                    key={m.frontmatter.title}
                    title={m.frontmatter.title}
                    excerpt={m.frontmatter.meta.description}
                    link={`/glossary/${_.kebabCase(m.frontmatter.title)}/`}
                  />
                ))}
              </div>
            </Skeleton>
          </div>
        </div>
      </div>
    </>
  )
}

const GridItem = ({ title, link, excerpt }) => {
  const [hover, setHover] = useState(false)
  return (
    <Link to={link}>
      <div
        className={styles.postCard}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div className={`${styles.cardContent} ${hover && styles.hovered}`}>
          <h3>{title}</h3>
          <p>{excerpt}</p>
        </div>
      </div>
    </Link>
  )
}

export default Grid
